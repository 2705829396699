import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  headerTitle: {
    display: 'block',
  },
  sectionUser: {
    display: 'flex',
  },
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  main: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(2),
  },
  footer: {
    position: 'fixed',
    bottom: '0',
    width: '100%',
    height: '44px',
    padding: '12px 20px',
    //padding: theme.spacing(3, 2),
    whiteSpace: 'nowrap',
    marginTop: 'auto',
    //background-color: '#f5f5f5',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[800],
  },
  table: {
  border: '1px',
},


  logo: { maxWidth: 160 } 
}));
 export default useStyles;
